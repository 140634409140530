<script>
</script>

<button on:click|preventDefault {...$$restProps}> <slot /> </button>

<style>
	button {
		background: rgb(255, 102, 0);
		color: #fff;
		padding: 8px 10px;
		border-radius: 3px;
		margin-right: 10px;
		/* font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif; */
		font-weight: bold;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;

		&:disabled {
			background: #ccc;
			opacity: 0.5;
			color: #666;
			cursor: not-allowed;
		}
	}

	.button-smaller {
		padding: 4px 8px;
	}
</style>
